<template>
  <div class="login">
    <div class="login-wrapper">
      <div class="login-title">
        <div class="login-image">
          <img src="@/assets/images/logo.png" alt="图片" />
        </div>
        <span>党员安全质量监督岗管理系统</span>
      </div>
      <div class="login-content">
        <el-form class="form" ref="ruleForm" :rules="rules" :model="ruleForm">
          <el-form-item class="item" prop="phone">
            <el-input v-model="ruleForm.phone" prefix-icon="iconfont icon-zhanghao1"></el-input>
          </el-form-item>
          <el-form-item  prop="password">
            <el-input type="password" @keyup.enter.native="submit" v-model="ruleForm.password" prefix-icon="iconfont icon-mima1"></el-input>
          </el-form-item>
          <span class="forgon" @click="forgetPass">忘记密码</span>
        </el-form>
        <div class="loginBtn">
          <el-button @click="submit">登录</el-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { isPhoneNum } from "@/utils/validate.js";
import { createNamespacedHelpers } from "vuex";
const { mapActions } = createNamespacedHelpers("user");
export default {
  name: "Login",
  data() {
    const validatePhone = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("不能为空"));
      } else if (!isPhoneNum(value)) {
        callback(new Error("请输入正确手机号"));
      }
      callback();
    };
    return {
      rules: {
        phone: { validator: validatePhone, trigger: "blur" },
        password: {required: true, message: '密码不能为空', trigger: 'blur'}
      },
      ruleForm: {
        phone: "",
        password: "",
      },
    };
  },
  methods: {
    ...mapActions(["userLogin"]),
    submit() {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          this.userLogin(this.ruleForm);
        }
      });
    },
    forgetPass() {
      console.log("忘记密码");
    },
  },
};
</script>

<style lang="scss" scoped>
.login {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100vw;
  background: url("../../assets/images/login.jpg") no-repeat center;
  background-size: cover;
  .login-wrapper {
    height: 630px;
    width: 630px;
    flex-direction: column;
    align-items: center;
    .login-title {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-bottom: 100px;
      .login-image {
        margin-bottom: 66px;
        height: 112px;
        width: 115px;
        overflow: hidden;
        img {
          width: 100%;
          height: 100%;
        }
      }
      span {
        font-size: 45px;
        font-family: PingFang SC;
        font-weight: 800;
        line-height: 0px;
        color: #ffffff;
      }
    }
    .login-content {
      display: flex;
      flex-direction: column;
      .form{
        display: flex;
        .item{
          margin-right: 20px;
        }
        div{
          width: 260px;
          height: 20px;
        }
        span{
          line-height: 40px;
          margin-left: 20px;
          color: #fff;
        }
      }
      .loginBtn{
        display: flex;
        justify-content: center;
        margin-top: 80px;
        button{
          width: 400px;
          height: 50px;
          background: #E32322;
          border-radius: 4px;
          border: none;
          color: #fff;
          font-size: 24px;
          font-family: PingFang SC;
        }
      }
    }
  }
}

::v-deep .el-input__inner{
  // padding: ;
  border: none;
  background-color: #B90006;
  outline: none;
  border-bottom: 1px solid #fff;
  border-radius: 0;
  // height: 30px;
  color: #ffff;
}
::v-deep .el-form-item__error{
  color: #ffff;
}
</style>
